<template>
  <div
    class="page case-studies"
    :loaded="Boolean(cases) && Boolean(page)"
  >
    <div class="page-header" v-if="page">
      <div class="wrapper">
        <div class="page-title">Our Clients</div>
        <h3>{{page.title}}</h3>
        <p>{{page.description}}</p>
      </div>
    </div>

    <!-- <section class="featured-case" v-if="featuredCase">
      <div class="wrapper">
        <FeaturedCase :featuredCase="featuredCase" />
      </div>
    </section> -->

    <section class="cases-list" ref="clients">
      <div class="wrapper" v-if="cases">
        <div class="selectors">
          <div class="selector">
            <Dropdown
              :options="sectorOptions"
              :value="selectedSector"
              @change="selectedSector = $event.value"
            />
          </div>
        </div>
        <div class="container">
          <router-link
            class="case"
            v-for="cs in cases.filter(c => !selectedSector || (c.sectors && c.sectors.includes(selectedSector)))"
            :to="`/case-studies/${encodeURIComponent(cs.company)}`"
            :key="cs.id"
          >
            <div class="case-sector" v-if="cs.sectors && cs.sectors.length">{{cs.sectors[0]}}</div>
            <div class="case-image">
              <img
                :src="cs.profile"
                :alt="cs.name"
              />
            </div>
            <h5>{{cs.name}}</h5>
            <p>{{cs.role}} @ {{cs.company}}</p>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import Dropdown from "./Dropdown.vue";
import FeaturedCase from "./FeaturedCase.vue";

export default {
  name: "CaseStudies",
  components: {
    Icon,
    Dropdown,
    FeaturedCase,
  },
  data() {
    return {
      cases: null,
      page: null,
      selectedSector: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    featuredCase() {
      if (!this.cases) return null;
      return this.cases.find(c => c.featured) || null;
    },
    sectorOptions() {
      const sectors = this.cases
        .reduce((cases, c) => {
          if (c.sectors) {
            return [
              ...cases,
              ...c.sectors.filter(s => !cases.includes(s)),
            ];
          }
          return cases;
        }, [])
        .sort((a, b) => a.localeCompare(b))
        .map(s => ({
          label: s[0].toUpperCase() + s.substr(1),
          value: s,
        }));
      return [
        { label: 'All sectors', value: null },
        ...sectors,
      ];
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
    page() {
      this.scrollToClients();
    },
    featuredCase() {
      this.scrollToClients();
    },
  },
  methods: {
    scrollToClients() {
      if (this.$route.hash === '#clients' && this.page && this.featuredCase) {
        this.$nextTick(() => {
          this.scrollToY(this.$refs.clients.offsetTop);
        });
      }
    },
    getItems() {
      if (this.pageRef) this.pageRef();
      if (this.casesRef) this.casesRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("casestudies")
        .onSnapshot((page) => {
          this.page = {
            title: "",
            subtitle: "",
            description: "",
            image: "",
            ...page.data(),
          };
        });
      this.casesRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}cases`)
        .onSnapshot((cases) => {
          this.cases = cases.docs
            .map((doc) => doc.data())
            .sort((a, b) => b.created_at - a.created_at);
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.casesRef) this.casesRef();
  },
};
</script>

<style scoped>
.page {
  background: url(../assets/images/wb_bg_cases.png) no-repeat top center/auto 100vh;
  padding-top: 0;
}

.page-header {
  margin: 0;
  padding: 288px 0 48px;
}

.page-header h3 {
  margin-bottom: 24px;
}

.page-header h3,
.page-header h5,
.page-header p {
  max-width: 715px;
}

.cases-list {
  margin: 0 0 32px;
}

.selectors {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 42px;
  font-weight: 600;
}

.selectors .selector {
  display: flex;
  align-items: center;
  width: 200px;
  height: 96px;
  padding: 0 32px;
}

.cases-list .wrapper > h3 {
  color: #1DACD6;
  text-align: center;
  margin-bottom: 24px;
}

.cases-list .wrapper > h5 {
  color: #656662;
  text-align: center;
  margin-bottom: 48px;
}

.cases-list .container {
  display: flex;
  flex-wrap: wrap;
}

.cases-list .case {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(33.3333333% - 8px);
  border-radius: 16px;
  background: #fff;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  padding: 16px 24px 48px;
  margin: 0 4px 64px;
  text-align: center;
  transition: all .2s ease-out;
}

#app[no-touch] .cases-list .case:hover {
  box-shadow: 0 24px 32px rgba(52, 52, 52, 0.12);
  transform: translateY(-8px);
}

.cases-list .case .case-sector {
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #1DACD6;
}

.cases-list .case .case-image {
  position: relative;
  width: 192px;
  height: 192px;
  margin: 32px auto 40px;
}

.cases-list .case .case-image:before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  top: -10%;
  left: -6%;
  background: url(../assets/images/wb_team_bubbles.svg) no-repeat top right/auto 100%;
}

.cases-list .case img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.featured-case {
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 1280px), screen and (max-height: 760px) {
  .cases-list .case {
    width: calc(50% - 8px);
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 1240px) {
  .page {
    background-size: auto 700px;
  }
}

@media screen and (max-width: 1024px) {
  .page-header {
    padding: 172px 0 120px;
  }
}

@media screen and (max-width: 650px) {
  .cases-list .case {
    width: 100%;
    margin-bottom: 24px;
  }
}
</style>
